/**
 * Komponente Dropdown
 */

import {
	debounce,
	execute,
	executeAfterTransition,
	extend,
	getUid,
	noop,
	triggerReflow
} from '../../utils';
import {isElement} from '../../utils/is';
import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../utils/scroll';
import {focusVisible}  from '../../utils/focus-visible';
import {getTabbableBoundary} from '../../utils/tabbable';

import Data           from '../../dom/data';
import Manipulator    from '../../dom/manipulator';
import SelectorEngine from '../../dom/selector-engine';
import EventHandler   from '../../dom/event-handler';

// -------
// Private
// -------

const NAME = 'dropdown';

const hideDropdownList = (element) => {
	Data.set(element, 'open', false);
	Manipulator.removeClass(element, '-active');
	
	// Event ´click´ (Document) entfernen.
	EventHandler.off(document.body, 'click.ifab.dropdownOutside');
};

const showDropdownList = (element) => {
	Data.set(element, 'open', true);
	Manipulator.addClass(element, '-active');
	
	// Event ´click´ (Document) schließt Dropdown (outside click).
	EventHandler.on(document.body, 'click.ifab.dropdownOutside', () => {
		hideDropdownList(element);
	});
};

// -------
// Public
// -------

const render = (element) => {
	EventHandler.on(element, 'click', `.${NAME}-trigger`, event => {
		event.stopPropagation();
		
		if(Data.get(element, 'open')) {
			hideDropdownList(element);
		} else {
			showDropdownList(element);
		}
	});

	EventHandler.on(element, `hide.ifab.${NAME}`, (event) => {
		hideDropdownList(element);
	});
};

const init = () => {
	const collection = SelectorEngine.find(`.${NAME}`);
	
	if(collection.length) {
		// for(const element of collection) {
		// 	render(element);
		// }
	}
};

// -------
// Export
// -------

export default {
	init: init,
	render: render
};