/**
 * Modul Steps
 */

import {
	needJquery,
	getTransitionDuration, extend
} from '../../../shared/utils/index';


import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator from "../../../shared/dom/manipulator";

import Stack from '../events/events';

// -------
// Private
// -------
const $ = needJquery();

/**
 * Konstruktor
 *
 * @param {String|Object} [m] - jQuery-Element oder -selektor.
 * @param {Object} [o={}] - Optionen.
 *
 * @constructor
 * @public
 *
 * @returns {Step}
 */
function Step(m, o) {
	if (typeof m === 'undefined' || !m.length) {
		return;
	}

	this.$container = $(m);
	this.settings   = $.extend(true, {}, this.DEFAULTS, o || {});
	this.mode       = this.$container.attr('data-module-steps');

	switch (this.mode)
	{
		// Auswahl Kategorie
		case 'select-category':
			this.stepSelectCategory();
			break;

		// Auswahl Geburtsdatum
		case 'select-dateofbirth':
			this.stepSelectDateOfBirth();
			break;

		// Auswahl Abschluß
		case 'select-direction':
			this.stepSelectDirection();
			break;

		// Bestätigung Voraussetzung
		case 'confirm-requirement':
			this.stepConfirmRequirement();
			break;

		// Eventauswahl(en)
		case 'select-event':
			this.stepEventList();
			break;
	}

	return this;
}

/**
 * Versionsnummer.
 *
 * @constant {String}
 */
Step.VERSION = '1.0.0';

/**
 * Standardoptionen.
 *
 * @constant {Object}
 */
Step.DEFAULTS = {};

/**
 * Methoden.
 *
 * @type {Object}
 */
Step.prototype = {
	/**
	 * Schritt 'Auswahl Kategorie'.
	 */
	stepSelectCategory: function() {
		var root = this,
			$form,
			$select,
			$next;

		$form = $('form', root.$container).submit(function(ev) {
			var v = $select.val();

			if (v === '')
			{
				ev.preventDefault();

				return false;
			}
		});

		$next = $('[data-btn-next]', $form).bind({
			'fbm.disable': function() {
				$(this)
					.prop('disabled', true)
					.closest('.box__footer').removeClass('-ready');
			},
			'fbm.enable' : function() {
				$(this)
					.prop('disabled', false)
					.closest('.box__footer').addClass('-ready');
			}
		});

		$select = $('select', $form)
			.on('change', function() {
				var v = $(this).val();

				if (v !== '')
				{
					$next.trigger('fbm.enable');

					$form.attr('action', v);
				}
				else
				{
					$next.trigger('fbm.disable');
				}
			})
			.trigger('change');
	},

	/**
	 * Schritt 'Auswahl Geburtsdatum'.
	 */
	stepSelectDateOfBirth: function() {
		var root   = this,
			$form  = $('form', root.$container),
			$input = $('[data-comp="datetime"]', $form),
			$next  = $('[data-btn-next]', $form),
			instD;

		$form.submit(function(ev) {
			var v = $input.val();

			if (v === '')
			{
				ev.preventDefault();

				return false;
			}
		});

		$next.bind({
			'fbm.disable': function() {
				$(this)
					.prop('disabled', true)
					.closest('.box__footer').removeClass('-ready');
			},
			'fbm.enable' : function() {
				$(this)
					.prop('disabled', false)
					.closest('.box__footer').addClass('-ready');
			}
		});

		$input.on('change', function() {
			var v = $(this).val();

			if (v !== '')
			{
				$next.trigger('fbm.enable');
			}
			else
			{
				$next.trigger('fbm.disable');
			}
		});

		$input.trigger('change');
	},

	/**
	 * Schritt 'Auswahl Abschluß, Weg'.
	 */
	stepSelectDirection: function() {
		var root = this,
			$form = $('form', root.$container),
			$select = $('select', $form),
			$next = $('[data-btn-next]', $form),
			$info = $('[data-info]', $form);

		$form.submit(function(ev) {
			var v = $select.val();

			if (v === '')
			{
				ev.preventDefault();

				return false;
			}
		});

		$next.bind({
			'fbm.disable': function() {
				$(this)
					.prop('disabled', true)
					.closest('.box__footer').removeClass('-ready');
			},
			'fbm.enable' : function() {
				$(this)
					.prop('disabled', false)
					.closest('.box__footer').addClass('-ready');
			}
		});

		$info
			.data('activeItem', '')
			.bind({
				'fbm.doToggle': function(ev, id) {
					var $this   = $(this),
						$old    = $this.data('activeItem'),
						$target = $this.find('[data-info-id="' + id + '"]');

					if ($old)
					{
						$old.slideUp(150, function() {
							if ($target)
							{
								$target.slideDown(150);
								$this.data('activeItem', $target);
							}
						});
					}
					else
					{
						$target.slideDown(150);
						$this.data('activeItem', $target);
					}
				},
				'fbm.doHide'  : function(ev) {
					var $this = $(this),
						$old  = $this.data('activeItem');

					if ($old)
					{
						$old.slideUp(150);
					}
				}
			});

		$select.on('change', function() {
			var v = $(this).val();

			if (v !== '')
			{
				$info.trigger('fbm.doToggle', [v]);

				$next.trigger('fbm.enable');
			}
			else
			{
				$info.trigger('fbm.doHide');

				$next.trigger('fbm.disable');
			}
		})
			.trigger('change');
	},

	/**
	 * Schritt 'Bestätigung Voraussetzung'.
	 */
	stepConfirmRequirement: function() {
		var root = this,
			$form = $('form', root.$container),
			$next = $('[data-btn-next]', $form),
			$radio = $('[type="radio"][name="vorhanden"]', $form),
			doCheck = function() {
				var v = $radio.filter(function() {
					return $(this).is(':checked');
				}).val();

				if (
					typeof v !== 'undefined' /*&&
						parseInt(v, 10) === 1*/
				) {
					$next.trigger('fbm.enable');
				}
				else
				{
					$next.trigger('fbm.disable');
				}
			};

		$form.submit(function(ev) {
			var v = $radio.val();

			if (v === '' || v !== '1')
			{
				ev.preventDefault();

				return false;
			}
		});

		$next.bind({
			'fbm.disable': function() {
				$(this)
					.prop('disabled', true)
					.closest('.box__footer').removeClass('-ready');
			},
			'fbm.enable' : function() {
				$(this)
					.prop('disabled', false)
					.closest('.box__footer').addClass('-ready');
			}
		});

		$radio.on('change', function() {
			doCheck();
		})
			.trigger('change');
	},

	/**
	 * Schritt 'Eventauswahl(en)'.
	 */
	stepEventList: function() {
		var root              = this,
			selectionRequired = (typeof root.$container.attr('data-required-select') !== 'undefined'),
			$form             = $('form[data-form-next]', root.$container),
			eventStackOptions = {},
			eventStack;

		this.bindBlockingEventsTo(root.$container);

		$form
			.bind({
				'fbm.doBlock': function(ev, cb) {
					$(this)
						.data('is-blocked', true)
						.attr('data-module-blocked', '');

					if (typeof cb === 'function')
					{
						cb();
					}
				},
				'fbm.doUnblock': function(ev, cb) {
					$(this)
						.data('is-blocked', false)
						.removeAttr('data-module-blocked');

					if (typeof cb === 'function')
					{
						cb();
					}
				}
			})
			.submit(function(ev) {
				if (root.$container.data('is-blocked') === true) {
					ev.preventDefault();

					return false;
				}
			});

		if (selectionRequired)
		{
			this.$container.trigger('fbm.doBlock');
		}

		eventStackOptions.onEventSuccess = function($event, eventData) {
			var cmd = (parseInt(eventData.showforward, 10) === 1) ? 'doUnblock' : 'doBlock';
			
			$form.trigger('fbm.' + cmd);

			root.$container.trigger('fbm.' + cmd);
		};

		eventStack = new Stack.Stack($('.event-stack', root.$container), eventStackOptions);
	},

	/**
	 * Block- Unblock-Events an Element anbinden
	 *
	 * @param {Object} $el - Element.
	 *
	 * @public
	 *
	 * @returns {Object}
	 */
	bindBlockingEventsTo: function($el) {
		return $el.bind({
			'fbm.doBlock'  : function(ev, cb) {
				var $this = $(this);

				$this
					.data('is-blocked', true)
					.attr('data-module-blocked', '');

				$this
					.find('[type="submit"][data-btn-next]')
					.prop('disabled', true);
			},
			'fbm.doUnblock': function(ev, cb) {
				var $this = $(this);

				$this
					.data('is-blocked', false)
					.removeAttr('data-module-blocked');

				$this
					.find('[type="submit"][data-btn-next]')
					.prop('disabled', false);
			}
		});
	}
};

// -------
// Public
// -------

const init = () => {
	const container = SelectorEngine.findOne('[data-module-steps]');

	if(container) {
		const api = new Step($(container));
		// return api;
	}
};

// -------
// Export
// -------

export default {
	init,
	Step
};