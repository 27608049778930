/**
 * Komponente Drawer
 */

import {
	execute,
	executeAfterTransition,
	extend,
	getUid,
	noop,
	triggerReflow
} from '../../utils';
import {isElement} from '../../utils/is';
import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../utils/scroll';
import {focusVisible}  from '../../utils/focus-visible';
import {getTabbableBoundary} from '../../utils/tabbable';

import Data           from '../../dom/data';
import Manipulator    from '../../dom/manipulator';
import SelectorEngine from '../../dom/selector-engine';
import EventHandler   from '../../dom/event-handler';

// -------
// Private
// -------

const NAME      = 'drawer';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
const API_KEY   = `${DATA_KEY}.data-api`;

const PLACEMENT_ALLOWED = ['top', 'bottom', 'start', 'end', 'center'];
const PLACEMENT_DEFAULT = 'end';

// -------
// Public
// -------

/**
 * Drawer
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {HTMLElement}
 * @returns {Object|null}
 * @constructor
 */
function Drawer(element, o = {}) {
	if (!isElement(element)) {
		return null;
	}

	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return Data.get(element, API_KEY);
	}

	// Aktuelle Einstellungen.
	this.options = extend({}, Drawer.DEFAULTS, o);

	// Container
	this._container        = element;
	this._container.hidden = true;

	// Aktueller Status.
	this._isContained     = Manipulator.hasDataAttribute(this._container, 'contained') || this.options.contained;
	this._isOpen          = false;
	this._isTransitioning = false;

	// Aktueller Trigger.
	// Aktuelles fokussiertes Element (vor dem Öffnen des Drawer) speichern,
	// damit nach dem Schließen der Drawer der Fokus wieder gesetzt werden kann.
	this._originalTrigger = null;

	// Position/Ausrichtung prüfen.
	let placement = Manipulator.getDataAttribute(this._container, 'placement') || this.options.placement;

	if (!PLACEMENT_ALLOWED.includes(placement)) {
		this.options.placement = placement = PLACEMENT_DEFAULT;
	}

	Manipulator.setDataAttribute(this._container, 'placement', placement);

	// Rendering (Container, Events, ...)
	this._render();

	// API-Zugriffsmöglichkiten
	const api = {
		hide  : this.hide.bind(this),
		show  : this.show.bind(this),
		toggle: this.toggle.bind(this)
	};

	// API im Container verfügbar machen
	Data.set(this._container, API_KEY, api);

	// Initialisierungsstatus setzen.
	Data.set(this._container,`${DATA_KEY}.initialized`, true);

	return api;
}

/**
 * Standardoptionen.
 *
 * @constant {Object}
 */
Drawer.DEFAULTS = {
	label       : '',
	closeLabel  : 'close',
	closeIcon   : '<i class="far fa-times"></i>',
	placement   : PLACEMENT_DEFAULT,
	closeByEsc  : true,
	setFocusBack: true,
	preventClose: false,
	noHeader    : false,
	contained   : false,
	onInit      : noop,
	onHide      : noop,
	onHidden    : noop,
	onShow      : noop,
	onShown     : noop
};

Drawer.prototype.show = function() {
	if (this._isOpen || this._isTransitioning) {
		return;
	}

	const relatedTarget = this._panel;
	const eventShow     = EventHandler.trigger(this._container, `show${EVENT_KEY}`, {
		relatedTarget
	});

	execute(
		this.options.onShow,
		eventShow
	);

	if (eventShow.defaultPrevented) {
		return;
	}

	// Wenn der Drawer (Panel) angezeigt wird, versucht Safari automatisch auf
	// das erste Element mit dem Attribut `autofocus` den Focus zu setzen.
	// Dies kann zu unschönen visuellen Erscheinungen führen. Von daher wird
	// `autofocus` entfernt und nach dem Öffnen des Drawer wieder reintegriert.
	const elementAutoFocus = SelectorEngine.findOne('[autofocus]', this._container);

	if (elementAutoFocus) {
		elementAutoFocus.removeAttribute('autofocus');
	}

	if (!this._isContained) {
		// Scrollen des Dokumentes sperren.
		lockBodyScrolling(this._container);
	}

	// Event ´ESC´ anbinden.
	if (this.options.closeByEsc) {
		EventHandler.on(this._container, `keyup${EVENT_KEY}`, (event) => {
			const key = event.key || event.keyCode;

			if (key === 'Escape' || key === 'esc' || key === 27) {
				this.hide();
			}
		});
	}

	this._originalTrigger = document.activeElement;
	this._isOpen          = true;
	this._isTransitioning = true;

	this._container.hidden = false;

	triggerReflow(this._container);

	Manipulator.addClass(this._container, '-open');
	Manipulator.setAria(this._panel, 'hidden', 'false');

	executeAfterTransition(
		() => {
			this._isTransitioning = false;

			const eventShown = EventHandler.trigger(this._container, `shown${EVENT_KEY}`, {
				relatedTarget
			});

			// Attribute `autofocus` reintegrieren.
			if (elementAutoFocus) {
				elementAutoFocus.setAttribute('autofocus', '');
			}

			const {start} = getTabbableBoundary(this._body);

			if (start && typeof start.focus === 'function') {
				start.focus({preventScroll: true});
			} else {
				this._body.focus({preventScroll: true});
			}

			execute(
				this.options.onShown,
				eventShown
			);
		},
		this._panel
	);
};

Drawer.prototype.hide = function() {
	if (!this._isOpen || this._isTransitioning) {
		return;
	}

	// Event ´ESC´ entfernen.
	if (this.options.closeByEsc) {
		EventHandler.off(this._container, `keyup${EVENT_KEY}`);
	}

	const relatedTarget = this._panel;
	const eventHide     = EventHandler.trigger(this._container, `hide${EVENT_KEY}`, {
		relatedTarget
	});

	execute(
		this.options.onHide,
		eventHide
	);

	if (eventHide.defaultPrevented) {
		return;
	}

	this._isOpen          = false;
	this._isTransitioning = true;

	Manipulator.removeClass(this._container, '-open');

	executeAfterTransition(
		() => {
			this._isTransitioning = false;

			this._container.hidden = true;

			Manipulator.setAria(this._panel, 'hidden', 'true');

			const eventHidden = EventHandler.trigger(this._container, `hidden${EVENT_KEY}`, {
				relatedTarget
			});

			execute(
				this.options.onHidden,
				eventHidden
			);

			// Scrollen des Dokumentes entsperren.
			unlockBodyScrolling(this._container);

			this._setFocusBack();

			this._originalTrigger = null;
		},
		this._panel
	);
};

Drawer.prototype.toggle = function() {
	if (!this._isTransitioning) {
		if (this._isOpen) {
			this.hide();
		} else {
			this.show();
		}
	}
};

Drawer.prototype._render = function() {
	if (this._isContained) {
		Manipulator.addClass(this._container, '-contained');

		const parent = this._container.parentNode;

		if (parent) {
			Manipulator.addClass(parent, 'contains-drawer');
		}
	}

	this._overlay = Manipulator.elementAppend(
		`<div class="${NAME}__overlay" tabindex="-1"/>`,
		this._container
	);

	this._btnClose = Manipulator.createElementFrom(`
		<button aria-label="${this.options.closeLabel}" class="button -default -s -circle ${NAME}__close" type="button">
			<span aria-hidden="true" class="button__label">${this.options.closeIcon}</span>
		</button>
	`);

	this._panel = Manipulator.elementAppend(
		`<div
			aria-hidden="true"
			aria-modal="true"
			class="${NAME}__panel"
			role="dialog"
			tabindex="0"
		/>`,
		this._container
	);

	this._header();
	this._body();
	this._footer();
	this._addListeners();

	Manipulator.addClass(this._container, '-initialized');

	const relatedTarget = this._panel;

	const eventInit = EventHandler.trigger(this._container, `init${EVENT_KEY}`, {
		relatedTarget
	});

	execute(
		this.options.onInit,
		eventInit
	);
};

Drawer.prototype._header = function() {
	const id = getUid(`${NAME}-header`);

	if (this.options.noHeader) {
		// Bezgl. ´Accessibility´ ist ein Attribut `aria-label` nötig!
		if (this.options.label) {
			Manipulator.setAria(this._panel, 'label', this.options.label);
		}
	} else {
		const tplHeader = SelectorEngine.findOne(`[data-${NAME}-header]`, this._container);

		let header = Manipulator.createElementFrom(`<header class="${NAME}__header"/>`);

		if (tplHeader) {
			header = this._copyContents(tplHeader, header);

			const customTitle = SelectorEngine.findOne(`${NAME}-title,.title,h1,h2,h3,h4,h5,h6`, header);

			if (customTitle) {
				customTitle.setAttribute('id', id);

				Manipulator.setAria(this._panel, 'labelledby', id);
			}

			Manipulator.elementAppend(this._btnClose, header);
			Manipulator.elementAppend(header, this._panel);

			tplHeader.remove();
		} else {
			const label = Manipulator.getDataAttribute(this._container, 'label') || this.options.label;

			if (label) {
				Manipulator.setAria(this._panel, 'labelledby', id);

				Manipulator.elementPrepend(
					`<div aria-level="2" class="${NAME}-title" id="${id}" role="heading">${label}</div>`,
					header
				);

				Manipulator.elementAppend(this._btnClose, header);
				Manipulator.elementAppend(header, this._panel);
			}
		}

		focusVisible.observe(this._btnClose);
	}
};

Drawer.prototype._body = function() {
	const tplBody = SelectorEngine.findOne(`[data-${NAME}-body]`, this._container);

	this._body = Manipulator.createElementFrom(`<div class="${NAME}__body"/>`);

	if (tplBody) {
		this._copyContents(tplBody, this._body);

		tplBody.remove();
	}

	Manipulator.elementAppend(this._body, this._panel);
};

Drawer.prototype._footer = function() {
	const tplFooter = SelectorEngine.findOne(`[data-${NAME}-footer]`, this._container);

	if (tplFooter) {
		let footer = Manipulator.createElementFrom(`<footer class="${NAME}__footer"/>`);

		this._copyContents(tplFooter, footer);

		tplFooter.remove();

		Manipulator.elementAppend(footer, this._panel);
	}
};

Drawer.prototype._addListeners = function() {
	EventHandler.on(this._panel, `click${EVENT_KEY}`, `.${NAME}__close`, event => {
		event.preventDefault();

		if (this._isOpen) {
			this.hide();
		}
	});

	EventHandler.on(this._container, `click${EVENT_KEY}`, `.${NAME}__overlay`, event => {
		event.preventDefault();
		event.stopPropagation();

		if (!this.options.preventClose && this._isOpen) {
			this.hide();
		}
	});
};

// Focus auf originalen Trigger ´zurücksetzen´.
Drawer.prototype._setFocusBack = function() {
	if (this.options.setFocusBack) {
		const trigger = this._originalTrigger;

		if (trigger && typeof trigger.focus === 'function') {
			setTimeout(() => trigger.focus());
		}
	}
};

Drawer.prototype._copyContents = function(from, target) {
	if (from) {
		const fNodes = SelectorEngine.children(from, '*');
		const fText  = (fNodes.length <= 0) ? from.textContent : '';

		if (fNodes.length > 0 || fText) {
			if (fNodes.length > 0) {
				for (const element of fNodes) {
					// target.appendChild(element.cloneNode(true));
					Manipulator.elementAppend(element, target);
				}
			} else {
				target.textContent = fText;
			}
		}
	}

	return target;
};

const renderDrawers = () => {
	const collection = SelectorEngine.find('[data-drawer-trigger]');

	if(collection.length) {
		for(const element of collection) {
			const id = Manipulator.getDataAttribute(element, 'drawer-trigger');

			if(!id) {
				continue;
			}

			const drawerEl = SelectorEngine.findOne(`${id}`);

			if(!drawerEl) {
				continue;
			}

			if(!drawerEl.classList.contains('drawer')) {
				continue;
			}

			const drawer  = new Drawer(drawerEl, {
				useOverlay : true,
				onShow     : (el) => {
					// Hier wird eine jQuery-Instanz zurückgegeben.
					const element = el[0];
				},
				onHide: (el) => {
					// Hier wird eine jQuery-Instanz zurückgegeben.
					const element = el[0];
				}
			});

			EventHandler.on(element, 'click', (event) => {
				drawer.show();
			});
		}
	}
};

// Export
export default Drawer;
export {
	renderDrawers
};
