/**
 * Komponente Modules
 */

import {
	needJquery,
	getTransitionDuration, extend
} from '../../../shared/utils/index';

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator    from '../../../shared/dom/manipulator';
import EventHandler   from '../../../shared/dom/event-handler';

import {confirm} from '../modules';

import Toast from "../../../shared/components/toast/toast";
import AjaxRequest from "../../../shared/utils/ajax";

// -------
// Private
// -------

const $ = needJquery();

const initFancybox = (src) => {
	const FbInstance = new Fancybox(
		[
			{
				src : src,
				type: "ajax"
			}
		],
		{
			backdropClick: false,
			closeButton  : false,
			Toolbar      : {
				enabled: false
			},
			on: {
				close: () => {
					location.reload();
				},
				done: (fancybox, current) => {
					const Fb = fancybox;

					$(`#${Fb.container.id} button.-remove`).on('click', function(event) {
						var id = $(this).attr('data-id');
						document.querySelector(`#wkrechadr_${id} .form-check:first-child .form-check-input`).click();

						setTimeout(function() {
							location.reload();
						}, 200);
					});

					$(`#${Fb.container.id} .form`).on('submit', function(event) {
						event.preventDefault();

						let $form = $(this);

						let o      = {
							url       : $form.attr('action'),
							method    : $form.attr('method') ?? 'get',
							data      : $form.serialize(),
							beforeSend: () => {
								Fb.showLoading(current);
							},
							// always: () => {
							// },
							done: () => {
								Fb.hideLoading(current);
							},
							success: (data, status, xhr) => {
								Fb.clearContent(current);

								setTimeout(function() {
									Fb.setContent(current, `<div>${xhr.responseText}</div>`);
								}, 100);
							}
						};
						const FbRequest = new AjaxRequest(o);

						FbRequest.init();
					});
				}
			}
		}
	);
};

const triggerEditAddressNew = (el, form) => {
	EventHandler.on(el, 'click', (event) => {
		event.preventDefault();

		const src = el.href;

		initFancybox(src);
	});
};

const triggerAddressNew = (el, form) => {
	const radio = SelectorEngine.findOne('.new-address', form);

	if(!radio) {
		return;
	}

	EventHandler.on(el, 'click', (event) => {
		event.preventDefault();

		radio.checked = true;

		let o      = {
			url       : form.action,
			method    : form.method ?? 'get',
			data      : $(form).serialize(),
			beforeSend: (data, status, xhr) => {
				console.log('sending');
			},
			always: () => {
				console.log('always');
			},
			done: () => {
				console.log('done');
			},
			success: (data, status, xhr) => {
				const src = el.href;

				initFancybox(src);
			},
			error: (data, status, xhr) => {
				console.log('An error occurred!');
			}
		};
		const Request = new AjaxRequest(o);

		Request.init();
	});
};

const triggerAddressChange = (el, form) => {
	EventHandler.on(el, 'change', (event) => {
		event.preventDefault();

		let o      = {
			url       : form.action,
			method    : form.method ? form.method : 'get',
			data      : $(form).serialize(),
			beforeSend: (data, status, xhr) => {
				Manipulator.setDataAttribute(form, 'is-rendering');
			},
			always: () => {
				console.log('always');
			},
			done: () => {
				console.log('done');
			},
			success: (data, status, xhr) => {
				setTimeout(function() {
					console.log(el.value);
				}, 100);
			},
			error: (data, status, xhr) => {
				console.log('An error occurred!');
			}
		};
		const Request = new AjaxRequest(o);

		Request.init();
	});
};

const initAddress = () => {
	const collection = SelectorEngine.find('[data-cart="edit-address"]');

	if(!collection.length) {
		return;
	}

	for(const form of collection) {
		const triggers = SelectorEngine.find('[data-cart-address]', form);

		if(!triggers.length) {
			continue;
		}

		for(const trigger of triggers) {
			const type = Manipulator.getDataAttribute(trigger, 'cart-address');

			if(type === 'new') {
				triggerAddressNew(trigger, form);
			} else if(type === 'editnew') {
				triggerEditAddressNew(trigger, form);
			} else {
				triggerAddressChange(trigger, form);
			}
		}
	}
};

const setupOrderOption = (container) => {
	const xhr = {
		set: container.dataset.srcSet ?? null,
		unset: container.dataset.srcUnset ?? null
	};

	if (!xhr.set || !xhr.unset) {
		return;
	}

	EventHandler.on(container, 'handle.options', (event) => {
		const checkedRadio = SelectorEngine.findOne('input[type="radio"]:checked', event.target);

		event.preventDefault();
		event.stopPropagation();

		if (checkedRadio) {
			const Request = new AjaxRequest({
				url: xhr[(parseInt(checkedRadio.value, 10) === 1) ? 'set' : 'unset'],
				method: 'get',
				data: {
					'rnd': Math.floor(Math.random() * (99999 - 99 + 1)) + 99
				},
				success: (data, status, xhr) => {},
				error: (data, status, xhr) => {
					console.log('An error occurred!');
				},
				// beforeSend: (data, status, xhr) => {},
				// always: () => {},
				// done: () => {}
			});

			Request.init();

		}
	});

	const radios = SelectorEngine.find('input[type="radio"]', container);

	for (const radio of radios) {
		EventHandler.on(radio, 'change', (event) => {
			event.preventDefault();
			event.stopPropagation();

			EventHandler.trigger(container, 'handle.options');
		});
	}
};

// -------
// Public
// -------

const init = () => {
	// Order options
	const collectionOrderOptions = SelectorEngine.find('[data-order-option]');

	for (const container of collectionOrderOptions) {
		setupOrderOption(container);
	}

	// Address
	initAddress();
};

// -------
// Export
// -------

export default {
	init : init
};
