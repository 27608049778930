/**
 * Vendors ´Fancybox´
 */

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator from '../../../shared/dom/manipulator';
import Login from '../../components/custom/login/login';

import config from '../../config/config';

// -------
// Private
// -------

const zoomImage = () => {
	Fancybox.bind('[data-zoomable]', {
		Carousel: {
			Navigation: {
				nextTpl: config.icons.next,
				prevTpl: config.icons.prev
			},
		},
		// groupAttr: false,
		groupAttr: 'data-fancybox',
		type     : 'image'
	});
};

const zoomIframe = () => {
	Fancybox.bind('[data-type="iframe"]', {
		groupAttr: false,
		type     : 'iframe',
		idle : false,
		Toolbar  : {
			enabled: true,
			display: {
				right : ["close"]
			}
		}
	});
};

const zoomHtml = () => {
    Fancybox.bind('[data-fancy-html]', {
        groupAttr: false,
        idle : false,
        Toolbar  : {
            enabled: true,
            display: {
                right : ["close"]
            }
        }
    });
};

// ------
// Public
// ------

const init = () => {
	//
	// Pluginstandards.
	//

	Fancybox.defaults.hideScrollbar = false;
	Fancybox.defaults.mainClass     = 'fancybox-custom';
	Fancybox.defaults.Thumbs        = false;
	Fancybox.defaults.l10n          =  {
		CLOSE   : window.ifabTM.translate('close'),
		DOWNLOAD: window.ifabTM.translate('download'),
		ERROR   : window.ifabTM.translate('error'),
		MODAL   : window.ifabTM.translate('closeEsc'),
		NEXT    : window.ifabTM.translate('next'),
		PREV    : window.ifabTM.translate('prev')
	};
	Fancybox.defaults.Toolbar = {
		items: {
			fullscreen: {
				tpl: '<button class="button -primary -circle" title="{{TOGGLE_FULLSCREEN}}" data-fancybox-toggle-fullscreen><span class="button__label"><i class="far fa-expand"></i></span></button>'
			},
			close: {
				tpl: '<button class="button -primary -circle" title="{{CLOSE}}" data-fancybox-close><span class="button__label"><i class="far fa-times"></i></span></button>'
			}
		}
	};

	Fancybox.defaults.caption = function(fancybox, carousel, slide) {
		if(!carousel.triggerEl) {
			return;
		}

		const figureCaption = SelectorEngine.next(carousel.triggerEl, '.figure__caption')[0];

		let caption = carousel.captionEl || '';

		if (figureCaption) {
			caption = figureCaption.innerHTML;
		}

		return caption;
	};

	Fancybox.Plugins.Toolbar.defaults.items.close.html = config.icons.close;

	//
	// Verfügbare ´Zoomtypen´ initialisieren.
	//

	zoomImage();
	zoomIframe();
	zoomHtml();
};

// Export
export default init;
