/**
 * Main(-JS-File)
 */

import {debounce, getRootVar} from '../shared/utils/index';

import componentShared  from '../shared';
import componentsCustom from './components/components';
import initial          from './initial/initial';
import modules          from './modules/modules';
import layout           from './layout/layout';
import vendors          from './vendors/vendors';

import GlobalAlert from '../shared/components/global-alert/global-alert';

// --------
// Let's go
// --------

document.addEventListener('DOMContentLoaded', function() {
	window.RootvarPrefix = getRootVar('global-rootvar-prefix');

	componentShared();
	componentsCustom();
	vendors();
	initial();
	modules();
	layout();
	
	// const options = {
	// 	cssClasses: '-danger',
	// 	closeByEsc: true,
	// 	tpl       : {
	// 		icon   : '<i class="far fa-info-circle"></i>',
	// 		title  : '<h4 class="title">Mein erster Alert</h4>',
	// 		body   : '<p>Das ist mein erster Alert<br>' +
	// 			'Bitte entscheiden Sie, ob Sie diese Nachricht annehmen oder nicht.</p>',
	// 		buttons: {
	// 			decline: {
	// 				tpl     : '<button class="button -danger -outline -pill -s"><span class="button__prefix"><i class="far fa-times"></i></span><span class="button__label">Nein</span></button>',
	// 				callback: function () {
	// 					console.log('Decline GlobalAlert');
	// 				}
	// 			},
	// 			accept: {
	// 				tpl     : '<button class="button -danger -pill -s"><span class="button__prefix"><i class="far fa-check"></i></span><span class="button__label">Ja</span></button>',
	// 				callback: function () {
	// 					console.log('Accept GlobalAlert');
	// 				}
	// 			}
	// 		}
	// 	},
	// };
	//
	// const FirstAlert = new GlobalAlert(options);
	//
	// FirstAlert.show();
});


// --------
// main.ctrl.js
// --------
//
// Tooltips
// isInView
// Fancybox

// --------
// common.js
// --------
//
// ScCookie

// --------
// event-stack.js
// --------
//
// Events