/**
 * Dynamic Ajax Html Rendering
 */

import SelectorEngine from '../../dom/selector-engine';
import Manipulator from '../../dom/manipulator';
import EventHandler from '../../dom/event-handler';

import AjaxRequest from "../../utils/ajax";
import {extend, needJquery} from "../../utils";

// -------
// Private
// -------

const $ = needJquery();

const DEFAULTS = {
	dataType: 'html'
};

const getConfig = (element)  => {
	if(!element) {
		return false;
	}

	const container    = element;
	const inlineConfig = SelectorEngine.findOne('script[type="application/json"][data-ajax-config]', container) ? SelectorEngine.findOne('script[type="application/json"][data-ajax-config]', container) : null;
	let cfg            = {};
	let _o             = {};

	if (!inlineConfig) {
		return false;
	}

	cfg = JSON.parse(inlineConfig.textContent) || {};
	// inlineConfig.remove();

	_o.cfg       = inlineConfig;
	_o.container = container;
	_o.selector  = cfg.ajax.selector;

	if(cfg && cfg !== '') {
		if(cfg.type === 'form') {
			const $form = $(SelectorEngine.findOne('.form', container));

			_o.url    = $form.attr('action');
			_o.data   = $form.serialize();
			_o.method = cfg.method ? cfg.method : 'GET';
		} else {
			_o.url = cfg.ajax.url;
		}
	}

	return _o;
};

const renderSpecificAjaxContent = (element) => {
    let o = getConfig(element);

    o.beforeSend = function() {
        Manipulator.setDataAttribute(o.container, 'is-rendering');
    };
    o.success = function(data, status, xhr) {
        const response = Request.getHtmlSnippet(o.selector, xhr);

        if (response.html)
        {
            setTimeout(function() {
				const tpl      = response.html;
				const configEl = SelectorEngine.next(_o.cfg, '[data-is-parsed]')[0];

				if(configEl) {
					configEl.remove();
				}

                Manipulator.elementAppend(tpl, o.container);
                Manipulator.setDataAttribute(tpl, 'is-parsed');
				Manipulator.removeDataAttribute(o.container, 'is-rendering');
            }, 1000);
        } else {
            console.log('No Response was found!');
        }
    };

    const _o = extend({}, DEFAULTS, o);

    const Request   = new AjaxRequest(_o);

    if(_o.selector && _o.container && _o.url) {
        Request.init(_o);
    }
};

const renderSpecificAjaxFormContent = (element, o) => {
	const $form = $(element);
	const _o    = extend({}, {
		dataType  : 'html',
		url       : $form.attr('action'),
		data      : $form.serialize(),
		method    : $form.attr('method') ? $form.attr('method') : 'GET',
		selector  : Manipulator.getDataAttribute(element, 'ajax-selector'),
		beforeSend: function() {
			if(!Manipulator.getDataAttribute(element, 'ajax-content')) {
				Manipulator.setDataAttribute(element, 'ajax-content-custom');
			}
			Manipulator.setDataAttribute(element, 'is-rendering');
		},
		success   : function(data, status, xhr) {
			const response = Request.getHtmlSnippet(_o.selector, xhr);

			if (response.html) {
				setTimeout(function() {
					const tpl = response.html;

					Manipulator.elementAfter(tpl, element);
					Manipulator.setDataAttribute(tpl, 'is-parsed');
					element.remove();
				}, 1000);
			} else {
				console.log('No Response was found!');
			}
		}
	}, o);

	const Request   = new AjaxRequest(_o);

	if(_o.selector && _o.url) {
		Request.init(_o);
	}
};

// -------
// Public
// -------

const init = ()  => {
	const collection = SelectorEngine.find('[data-ajax-content]');

    if(collection.length) {
        for (const element of collection) {
            renderSpecificAjaxContent(element);
        }
    }
    
    $(document).on('submit', '.form[data-ajax-content="form"]', function(event) {
        event.preventDefault();
        
        renderSpecificAjaxFormContent($(this).closest("[data-ajax-content]")[0]);
    });
};

// -------
// Export
// -------

export default {
	init                         : init,
	renderSpecificAjaxContent    : renderSpecificAjaxContent,
	renderSpecificAjaxFormContent: renderSpecificAjaxFormContent
};