/**
 * Simuliert das Verhalten `:focus-visible` auf einem Element, schaltet eine
 * CSS-Klasse `-focus-visible` um, indem auf bestimmte Tastatur- und Mausevents
 * geachtet wird.
 *
 * Wird zukünftig wieder entfernt wenn ein beserer Support für `:focus-visible`
 * vorhanden ist (https://caniuse.com/#search=focus-visible).
 */

import EventHandler from '../dom/event-handler';
import Manipulator from "../dom/manipulator";
import Data from "../dom/data";

import {extend} from "./index";

// -------
// Private
// -------

const currentDate = new Date();

// -------
// Public
// -------

function AjaxRequest (o = {}) {
	this.options = extend({}, AjaxRequest.DEFAULTS, o);

	if(!this.options.url && !this.options.dataType) {
		return null;
	}
}

AjaxRequest.DEFAULTS    = {
	contentType: null,
	url        : null,
	dataType   : null,
	method     : 'GET',
	data       : {},
	serialize  : null,
	cache      : false,
	ts         : currentDate.getTime(),
	beforeSend : function(data, status, xhr) {
		console.log('Doing something before request is send.');
		return {
			data  : data,
			status: status,
			xhr   : xhr
		};
	},
	success: function(data, status, xhr) {
		console.log('Request is successful.');
		return {
			data  : data,
			status: status,
			xhr   : xhr
		};
	},
	fail: function(data, status, xhr) {
		console.log('Request failed.');
		return {
			data  : data,
			status: status,
			xhr   : xhr
		};
	},
	always: function(data, status, xhr) {
		console.log('Get done or failed outcome.');
		return {
			data  : data,
			status: status,
			xhr   : xhr
		};
	},
	error: function(data, status, xhr) {
		console.log('An Error occurred.');
		return {
			data  : data,
			status: status,
			xhr   : xhr
		};
	},
	done: function(data, status, xhr) {
		console.log('Request is done.');
		return {
			data  : data,
			status: status,
			xhr   : xhr
		};
	}
};

AjaxRequest.prototype.init = function(o = {}) {
	return $.ajax(this.options);
};

AjaxRequest.prototype.getHtmlSnippet = function(selector, xhr) {
	const parser   = new DOMParser();
	let response   = {
		html: null
	};
	
	if(selector) {
		if (xhr.readyState === 4) {
			if (xhr.status === 200) {
				let responseHtml = parser.parseFromString(xhr.responseText, "text/html");
				response.html =  responseHtml.querySelector(selector);

				return response;

			} else if (xhr.status === 400) {
				console.log('There was an error 400');

				return 'Error 400';
			}
			else {
				console.log('something else other than 200 was returned');

				return 'Error';
			}
		}
	} else {
		return null;
	}
};

AjaxRequest.prototype.getJson = function() {
	let response   = {
		json: null
	};

	if (xhr.readyState === 4) {
		if (xhr.status === 200) {
			response.json =  responseHtml.querySelector(selector);

			return response;

		} else if (xhr.status === 400) {
			console.log('There was an error 400');

			return 'Error 400';
		}
		else {
			console.log('something else other than 200 was returned');

			return 'Error';
		}
	}
};

// -------
// Export
// -------

export default AjaxRequest;