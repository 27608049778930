/**
 * Komponente Modules
 */

import {
    needJquery,
    getTransitionDuration, extend
} from '../../../shared/utils/index';

import Toast from "../../../shared/components/toast/toast";

import {
    lockBodyScrolling,
    unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator    from '../../../shared/dom/manipulator';
import EventHandler   from '../../../shared/dom/event-handler';

import {confirm} from '../modules';

// -------
// Private
// -------

const $ = needJquery();

// -------
// Public
// -------

const init = () => {
    "use strict";
    
    var $container = $("[data-module-user]").eq(0);
    
    const modules = {};
    
    modules.education = function () {
        $("[data-order-remove]", $container).on("click", function (ev) {
            console.log('education');
            
            var $this = $(this),
                $tgtForm = $($this.attr("data-order-remove"));
            
            ev.preventDefault();

			Toast.render({
				autohide: false,
				cssClass: 'warning',
				content : {
					heading: 'Veranstaltung stornieren?',
					icon   : '<i class="far fa-exclamation-circle"></i>',
					buttons: {
						0 : {
							tpl : `<a href="#" type="button" class="button -default -pill -s"><span class="button__prefix"><i class="far fa-check"></i></span><span class="button__label">stornieren</span></a>`,
							func: () => {
								$tgtForm.submit();
							}
						},
						1 : {
							tpl : '<button type="button" class="button -default -pill -s" data-bs-dismiss="toast"><span class="button__prefix"><i class="far fa-xmark"></i></span><span class="button__label">abbrechen</span></button>'
						}
					}
				}
			});
            
            // $tgtForm.submit();
            
            // confirm({
            //     callback: function () {
            //         $tgtForm.submit();
            //     },
            //     message: "Möchten Sie sich von dieser Veranstaltung abmelden?",
            //     noButton: "Nein",
            //     okButton: "OK",
            //     title: "Achtung",
            //     type: "info"
            // });
        });
    };
    
    modules.qualification = function () {
        const $form = $("#form-qualifikation", $container);
        $("[data-ctrl-qualification-remove]", $form).each(function () {
			const
				$btn    = $(this),
				$hidden = $("#" + $btn.attr("data-ctrl-qualification-remove"));

            $btn.on("click", function (ev) {
				Toast.render({
					autohide: false,
					cssClass: 'warning',
					content : {
						heading: 'Qualifikation entfernen?',
						icon   : '<i class="far fa-exclamation-circle"></i>',
						buttons: {
							0 : {
								tpl : `<a href="#" type="button" class="button -default -pill -s"><span class="button__prefix"><i class="far fa-check"></i></span><span class="button__label">entfernen</span></a>`,
								func: () => {
									if($hidden) {
										$hidden.val(1);
										$form.submit();
									} else {
										$hidden.val(0);
									}
								}
							},
							1 : {
								tpl : '<button type="button" class="button -default -pill -s" data-bs-dismiss="toast"><span class="button__prefix"><i class="far fa-xmark"></i></span><span class="button__label">abbrechen</span></button>'
							}
						}
					}
				});
                // ev.preventDefault(), confirm({
                //     callback: function (value) {
                //         value ? ($hidden.val(1), $form.submit()) : $hidden.val(0)
                //     },
                //     message: "Möchten Sie wirklich diese Qualifikation entfernen?",
                //     noButton: "Nein",
                //     okButton: "OK",
                //     title: "Achtung",
                //     type: "info"
                // });
            });
        });
    };
    modules.subaccount = function () {
		$("[data-order-remove]", $container).on("click", function (ev) {
			console.log('subaccount');

			var $this = $(this),
				$tgtForm = $($this.attr("data-order-remove"));

			ev.preventDefault();

			Toast.render({
				autohide: false,
				cssClass: 'warning',
				content : {
					heading: 'Unteraccount löschen?',
					icon   : '<i class="far fa-exclamation-circle"></i>',
					buttons: {
						0 : {
							tpl : `<a href="#" type="button" class="button -default -pill -s"><span class="button__prefix"><i class="far fa-check"></i></span><span class="button__label">löschen</span></a>`,
							func: () => {
								$tgtForm.submit();
							}
						},
						1 : {
							tpl : '<button type="button" class="button -default -pill -s" data-bs-dismiss="toast"><span class="button__prefix"><i class="far fa-xmark"></i></span><span class="button__label">abbrechen</span></button>'
						}
					}
				}
			});

			// $tgtForm.submit();

			// confirm({
			//     callback: function () {
			//         $tgtForm.submit();
			//     },
			//     message: "Möchten Sie sich von dieser Veranstaltung abmelden?",
			//     noButton: "Nein",
			//     okButton: "OK",
			//     title: "Achtung",
			//     type: "info"
			// });
		});
    };
    modules.addresses = function () {
        $("[data-order-remove]", $container).on("click.removeAddress", function (ev) {
            var $form = $($(this).attr("data-order-remove"));
            0 < $form.length && confirm({
                callback: function (value) {
                    value && $form.submit();
                },
                message: "Möchten Sie diesen Eintrag wirklich entfernen?",
                noButton: "Nein",
                okButton: "OK",
                title: "Achtung",
                type: "info"
            });
        });
    };
    modules.deleteProfile = function () {
        function doCheck() {
            var flag = $enableSubmit.filter(function () {
                return $(this).is(":checked");
            });
            return (flag = $enableSubmit.length === flag.length) ? $next.trigger("fbm.enable") : $next.trigger("fbm.disable"), flag
        }
        
        var $form = $("form", $container),
            $next = $("[data-btn-next]", $form),
            $enableSubmit = $('[type="checkbox"][data-enable-submit]', $form);
        $form.submit(function (ev) {
            if (!doCheck()) return ev.preventDefault(), !1
        }), $next.bind({
            "fbm.disable": function () {
                $(this).prop("disabled", !0).closest(".box__footer").removeClass("-ready")
            }, "fbm.enable": function () {
                $(this).prop("disabled", !1).closest(".box__footer").addClass("-ready")
            }
        }), $enableSubmit.on("change", function () {
            doCheck();
        }), doCheck();
    };
    
    if($container.length) {
        switch ($container.attr('data-module-user')) {
            case"login":
                modules.login();
                break;
            case"education":
                modules.education();
                break;
            case"qualification":
                modules.qualification();
                break;
            case"subaccount":
                modules.subaccount();
                break;
            case"addresses":
                modules.addresses();
                break;
            case"deleteprofile":
                modules.deleteProfile();
        }
    }
};

// -------
// Export
// -------

export default {
    init
};