/**
 * Login
 */

import {getUid} from './index';

import SelectorEngine from '../dom/selector-engine';
import Manipulator    from '../dom/manipulator';
import EventHandler   from '../dom/event-handler';

import AjaxRequest   from './ajax';

// -------
// Private
// -------

const LOC      = window.location;

const DEFAULTS = {
	default: {
		url: 'anmeldung.html?ltype=n',
		urlParameter: {
			stateBlock: '&state=1',
			action: {
				user: '&act=lname',
				password: '&act=lpass',
				state: '&act=state'
			},
			values: {
				user: '&login=',
				password: '&lpass=',
				rnd: '&rnd='
			}
		}
	},
	elements: {}
};

const renderElement = (element, uid) => {
	let container;

	if(SelectorEngine.findOne(`[data-lse="${uid}"]`)) {
		container = SelectorEngine.findOne(`[data-lse="${uid}"]`);
	} else {
		container = Manipulator.createElementFrom(`<div data-lse="${uid}"></div>`);
		Manipulator.elementAfter(container, element);
		element.remove();
	}

	Manipulator.setDataAttribute(container, 'is-loading');

	if(window.loggedIn) {
		container.innerHTML = DEFAULTS.elements[`${uid}`].loggedIn;
	} else {
		container.innerHTML = DEFAULTS.elements[`${uid}`].loggedOut;
	}

	setTimeout(function() {
		DEFAULTS.elements[`${uid}`].rendered = true;
		Manipulator.removeDataAttribute(container, 'is-loading');
	}, 500);
};

const pushElement = (element) => {
	const cfg = JSON.parse(element.textContent) || {};
	let uid;
	cfg.rendered = false;

	if(!cfg.uid) {
		cfg.uid = getUid('id-');
	}

	uid = cfg.uid;

	if(!DEFAULTS.elements[`${uid}`]) {
		DEFAULTS.elements[`${uid}`] = cfg;
	}

	renderElement(element, uid);
};

// -------
// Public
// -------

const renderElementToLoginStatus = (element) => {
	if(element.tagName === 'SCRIPT') {
		pushElement(element);

	} else {
		renderElement(element, Manipulator.getDataAttribute(element, 'lse'));
	}
};

const writeLoginStatus = (cb) => {
	window.loggedIn = 0;

	if(window.location.pathname !== '/fbm-grundsystem/verwaltung/index.php') {
		const Request = new AjaxRequest({
			url: DEFAULTS.default.url + DEFAULTS.default.urlParameter.action.state,
			dataType: 'json',
			success : (data, status, xhr) => {
				if(xhr && xhr.responseJSON) {
					window.loggedIn = xhr.responseJSON.state === 'active' ? 1 : 0;

					if(cb && typeof cb === 'function') {
						cb();
					}
				}
			}
		});

		Request.init();
	}
};

// -------
// Export
// -------

export {
	writeLoginStatus,
	renderElementToLoginStatus
};