/**
 * Components
 */

import Account from './custom/account/account';
import Login from './custom/login/login';
import GlobalToasts from './custom/system/global-toasts';

// Modules
import User from '../modules/user/user';
import Events from '../modules/events/events';
import Steps from '../modules/steps/steps';

// ------
// Public
// ------

const components = () => {
	Account.init();
	Login.init();
	// Events.init();
    User.init();
    Steps.init();
	GlobalToasts.init();
};

// Export
export default components;
