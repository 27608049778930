/**
 * Vendors ´Flatpickr´
 */

import Data           from '../../../shared/dom/data';
import Manipulator    from '../../../shared/dom/manipulator';
import SelectorEngine from '../../../shared/dom/selector-engine';

import {extend, needJquery} from "../../../shared/utils";

import l10n from '../../../../node_modules/flatpickr/dist/esm/l10n/de';
// -------
// Private
// -------

const $ = needJquery();

const DEFAULTS = {
	allowInput: true,
	altInput  : true,
	altFormat : 'd.m.Y',
	dateFormat: 'd.m.Y',
	enableTime: false,
	locale    : l10n.de,
	// minDate   : "today"
	wrap: true,
	// monthSelectorType : 'static'
};

// ------
// Public
// ------

const render = (element, o = {}) => {
	const type   = Manipulator.getDataAttribute(element, 'datepicker');
	const config = extend({}, DEFAULTS, o);

	element.setAttribute('autocomplete', 'off');

	if(type === 'datetime') {
		config.altFormat  = 'd.m.Y H:i';
		config.dateFormat = 'd.m.Y H:i';
		config.enableTime = true;
	}

	flatpickr(element, config);
};

const init = () => {
	const collection = SelectorEngine.find('[data-datepicker]');

	if(collection.length) {
		for(const element of collection) {
			render(element);
		}
	}
};

// Export
export default {
	init  : init,
	render: render
};
