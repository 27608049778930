/**
 * Vendors ´Bootstrap´
 */

import Data           from '../../../shared/dom/data';
import Manipulator    from '../../../shared/dom/manipulator';
import SelectorEngine from '../../../shared/dom/selector-engine';

import {extend, needJquery} from "../../../shared/utils";

import l10n from '../../../../node_modules/flatpickr/dist/esm/l10n/de';
// -------
// Private
// -------

const $ = needJquery();

const DEFAULTS = {

};

// ------
// Public
// ------

const initTooltips = () => {
	const collection = SelectorEngine.find('[data-bind="tooltip"]');
	const tooltipTriggerList = SelectorEngine.find('[data-bs-toggle="tooltip"]');
	const tooltipList = [...tooltipTriggerList, ...collection].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
};

const init = () => {
	initTooltips();
};

// Export
export default {
	init: init
};
