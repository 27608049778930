/**
 * Global Toasts
 */

import {needJquery} from '../../../../shared/utils/index';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Manipulator    from '../../../../shared/dom/manipulator';
import EventHandler   from '../../../../shared/dom/event-handler';

import Toast from '../../../../shared/components/toast/toast';

// -------
// Private
// -------
const defaultLang = 'de';

const DEFAULT     = {
	Translations: {
		de: {
			default: {
				'saved'        : 'Die Einstellungen wurden erfolgreich gespeichert.',
				'online'       : 'Der Eintrag wurde aktiviert.',
				'activate'     : 'Der Eintrag wurde online gestellt.',
				'offline'      : 'Der Eintrag wurde deaktiviert.',
				'deactivate'   : 'Der Eintrag wurde offline gestellt.',
				'positionDown' : 'Der Eintrag wurde nach unten verschoben.',
				'positionUp'   : 'Der Eintrag wurde nach oben verschoben.',
				'levelDown'    : 'Der Eintrag wurde eine Ebene nach unten verschoben.',
				'levelUp'      : 'Der Eintrag wurde eine Ebene nach oben verschoben.',
				'copy'         : 'Der Eintrag wurde nach kopiert.',
				'delete'       : 'Der Eintrag wurde gelöscht.',
				'deleteuser'   : 'Der Nutzer wurde gelöscht.',
				'editcanceled' : 'Vorgang abgebrochen.',
				'memospeichern': 'Das Memo wurde erfolgreich gespeichert.'
			},
			event: {
				'default'      : 'Die Einstellungen der Veranstaltung wurden erfolgreich gespeichert.',
				'memospeichern': 'Das Memo wurde erfolgreich gespeichert.',
				'deleteFile'   : 'Die Datei wurde erfolgreich gelöscht.',
				'protectFile'  : 'Die Datei-Einstellungen wurden gespeichert.',
				'unprotectFile': 'Die Datei-Einstellungen wurden gespeichert.',
				'upload'       : 'Die Datei wurde hinzugefügt.',
				'close'        : 'Die Veranstaltung wurde abgesagt.',
				'public_on'    : 'Die Veranstaltung ist jetzt öffentlich.',
				'public_off'   : 'Die Veranstaltung ist jetzt nicht mehr öffentlich.'
			},
			mailing: {
				'default': 'Die Einstellungen der E-Mail-Vorlage wurden erfolgreich gespeichert.',
				'positionDown': ''
			},
			enrollment: {
				'default'      : 'Die Einstellungen der Veranstaltung wurden erfolgreich gespeichert.',
				'memospeichern': 'Das Memo wurde erfolgreich gespeichert.',
				'close'        : 'Die Veranstaltung wurde abgesagt.',
			},
			confirm: {
				'default': 'Möchten Sie den Eintrag wirklich löschen?'
			},
			participants: {
				'deleteuser': 'Der Teilnehmer wurde gelöscht.'
			}
		}
	}
};

const showActionToasts = () => {
	const Params = new URLSearchParams(window.location.search);
	const mode   = Params.get('modulmodus[1]');
	const action = Params.get('action');

	let translate;
	let msg = null;

	if(action) {
		translate = DEFAULT.Translations[document.documentElement.lang] ? DEFAULT.Translations[document.documentElement.lang] : DEFAULT.Translations.de;

		if(mode) {
			if(typeof translate[mode] !== 'undefined') {
				if(typeof translate[mode][action] !== 'undefined') {
					msg = translate[mode][action];
				} else if(typeof translate['default'][action] !== 'undefined') {
					msg = translate['default'][action];
				} else if(typeof translate[mode]['default'] !== 'undefined') {
					msg = translate[mode]['default'];
				}
			} else {
				if(typeof translate['default'][action] !== 'undefined') {
					msg = translate['default'][action];
				}
			}
		} else {
			if(typeof translate['default'][action] !== 'undefined') {
				msg = translate['default'][action];
			}
		}
		
		if(!msg) {
			return;
		} else {
			Toast.render({
				cssClass: 'success',
				content : {
					heading: 'Information',
					icon   : '<i class="far fa-info-circle"></i>',
					msg    : '<span>' + msg + '</span>'
				}
			});
		}
	}
};

const addConfirmToasts = () => {
	// const collection = SelectorEngine.find('[data-toast="confirm"]');
	const collection = SelectorEngine.find('.toast-confirm');

	if(collection.length) {
		for (const element of collection) {
			EventHandler.on(element, 'click', (event) => {
				event.preventDefault();

				let content = {
					heading: 'Handlung erforderlich',
					icon   : '<i class="far fa-exclamation-circle"></i>',
					msg    : '<span>' + DEFAULT.Translations[defaultLang].confirm.default + '</span>',
					buttons: {
						1 : {
							tpl : '<button type="button" class="button -default -s" data-bs-dismiss="toast"><span class="button__prefix"><i class="far fa-xmark"></i></span><span class="button__label">abbrechen</span></button>'
						}
					}
				};

				if(element.hasAttribute('href')) {
					let href = element.href;

					content.buttons[0] = {
						tpl : `<a href="${href}" type="button" class="button -default -s"><span class="button__prefix"><i class="far fa-check"></i></span><span class="button__label">löschen</span></a>`
					};

				} else if(element.hasAttribute('type')) {
					let form = SelectorEngine.parents(element, 'form')[0];

					content.buttons[0] = {
						tpl : `<button type="button" type="button" class="button -default -s"><span class="button__prefix"><i class="far fa-check"></i></span><span class="button__label">löschen</span></button>`,
						func : () => {
							if(element.hasAttribute('value') && element.hasAttribute('name')) {
								let value = element.getAttribute('value');
								let name = element.getAttribute('name');
								let input = Manipulator.createElementFrom(`<input value="${value}" name="${name}" type="hidden"/>`);

								Manipulator.elementAppend(input, form);
							}

							setTimeout(function() {
								form.submit();
							}, 200);
						}
					};
				} else {
					content.buttons[0] = null;
				}

				if(!content.buttons[0]) {
					return;
				}

				Toast.render({
					autohide: false,
					cssClass: 'warning',
					content : content
				});
			});
		}
	}
};

// -------
// Public
// -------

const init = () => {
	showActionToasts();
	addConfirmToasts();
};

// -------
// Export
// -------

export default {
	init: init
};