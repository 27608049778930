/**
 * Login
 */

import {extend} from '../../../../shared/utils/index';
import {
    writeLoginStatus,
    renderElementToLoginStatus
} from '../../../../shared/utils/login';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Manipulator from '../../../../shared/dom/manipulator';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from "../../../../shared/dom/data";

import AjaxRequest   from '../../../../shared/utils/ajax';
import Ajax from '../../../../shared/components/ajax/ajax';
import Drawer from "../../../../shared/components/drawer/drawer";

import {needJquery} from "../../../../shared/utils/index";
import Toast from "../../../../shared/components/toast/toast";
import Account from "../account/account";
import RevealPassword from "../../../../shared/components/reveal-password/reveal-password";

// Modules
import Events from '../../../modules/events/events';

// -------
// Private
// -------
const $        = needJquery();
const LOC      = window.location;
const DEFAULTS = {
    url: 'anmeldung.html?ltype=n',
    urlParameter: {
        stateBlock: '&state=1',
        action: {
			logout  : '&logout=1',
			password: '&act=lpass',
			state   : '&act=state',
			user    : '&act=lname'
		},
        values: {
			password: '&lpass=',
			rnd     : '&rnd=',
			user    : '&login='
		}
    }
};

const o = {
    dataType: "json"
};

const renderDrawer = (container, toggleCollection) => {
	const LoginDrawer = new Drawer(container);

    $(document).on('click', '[data-drawer-login]', function(event) {
        event.preventDefault();

        LoginDrawer.show();
    });
};

const renderElementsToLoginStatus = (cb) => {
	const collection = SelectorEngine.find('[data-lse]');
	let counter      = 1;

    if(collection) {
        for(const element of collection) {
			if(counter === collection.length) {
				const list       = SelectorEngine.findOne('[data-module="event-stack"]');
				const EventStack = Data.get(list, 'ifab.events.data-api');

				if(Data.get(list, 'ifab.events.initialized')) {
					EventStack.reload();
				} else {
					Events.init();
				}
			}

			renderElementToLoginStatus(element);

			counter++;
        }
    }
};

const newLogin = () => {
	const form  = SelectorEngine.findOne('#newForm');
	const steps = {
		rnd     : '&rnd=0',
		current : 'username',
		username: {
			tpl: {
				1: `<input type="hidden" name="act" value="lname" />`,
				2: `<div class="form-group"><label class="form-label">E-Mail-Adresse</label><input class="form-control" name="login" required /></div>`,
				3: `<a href="passwordforget.html" class="icon-link -pwforget"><span class="icon-link__icon"><i class="far fa-question-circle"></i></span><span class="icon-link__label">Passwort vergessen</span></a>`,
				4: `<button type="submit" class="button -primary -pill -s"><span class="button__label">weiter</span><span class="button__suffix"><i class="far fa-arrow-right"></i></span></button>`
			},
			actions: {
				success: (data, status, xhr) => {
					if(data.state === 'proceed') {
						steps.rnd     = data.rnd ? `&rnd=${data.rnd}` : '&rnd=0';
						steps.current = 'password';

						renderTpl();

					} else if(data.state === 'fail') {
						Toast.render({
							autohide: true,
							cssClass: 'danger',
							content : {
								heading: 'Fehler bei der Anmeldung',
								icon   : '<i class="far fa-info-circle"></i>',
								msg    : '<span>Bitte geben Sie einen Nutzernamen ein!</span>'
							}
						});
					} else {
						console.log('A login error occurred!');
					}
				},
				error: (data, status, xhr) => {
					console.log('An ajax error occurred!');
				}
			}
		},
		password: {
			tpl: {
				1: `<input type="hidden" name="act" value="lpass" />`,
				2: `<div class="form-group"><label class="form-label">Passwort</label><input class="form-control -pw" name="lpass" type="password" required data-reveal-password="1" /></div>`,
				3: `<a href="passwordforget.html" class="icon-link -pwforget"><span class="icon-link__icon"><i class="far fa-question-circle"></i></span><span class="icon-link__label">Passwort vergessen</span></a>`,
				4: '<button type="button" class="button -default -pill -s" data-step="username"><span class="button__prefix"><i class="far fa-arrow-left"></i></span><span class="button__label">zurück</span></button>',
				5: `<button type="submit" class="button -primary -pill -s"><span class="button__label">anmelden</span><span class="button__suffix"><i class="far fa-check"></i></span></button>`
			},
			actions: {
				success: (data, status, xhr) => {
					if(data.state === 'ok') {
						steps.rnd     = '&rnd=0';
						steps.current = 'username';

						writeLoginStatus(function() {
							renderElementsToLoginStatus();
						});

						const drawer  = SelectorEngine.parents(form, '.drawer')[0];
						const apiDrawer = drawer ? Drawer(drawer) : null;

						if(apiDrawer) {
							apiDrawer.hide();
						}

						Toast.render({
							cssClass: 'success',
							content : {
								heading: 'Anmeldung erfolgreich',
								icon   : '<i class="far fa-circle-check"></i>',
								msg    : '<span>Sie haben sich erfolgreich angemeldet!</span>'
							}
						});

						renderTpl();

						setTimeout(function() {
							Account.buildAccount(data);
						}, 500);
					} else if(data.state === 'fail') {
						Toast.render({
							autohide: true,
							cssClass: 'danger',
							content : {
								heading: 'Fehler bei der Anmeldung',
								icon   : '<i class="far fa-info-circle"></i>',
								msg    : '<span>Nutzername und Passwort stimmen leider nicht überein!</span>'
							}
						});
					} else {
						console.log('A login error occurred!');
					}
				},
				error: (data, status, xhr) => {
					console.log('An ajax error occurred!');
				}
			}
		}
	};

	if(!form) {
		return false;
	}

	EventHandler.on(form, 'submit', (event) => {
		event.preventDefault();

		const form = event.delegateTarget;
		const step = SelectorEngine.findOne('.step', form);
		let o      = {
			url       : form.action,
			dataType  : 'json',
			method    : form.method ? form.method : 'get',
			data      : $(form).serialize() + steps.rnd,
			beforeSend: (data, status, xhr) => {
				Manipulator.setDataAttribute(step, 'is-rendering');
			},
			always: () => {
				console.log('always');
			},
			done: () => {
				console.log('done');
			},
			success: (data, status, xhr) => {
				setTimeout(function() {
					if(typeof steps[`${steps.current}`].actions.success === 'function') {
						steps[`${steps.current}`].actions.success(data, status, xhr);
						Manipulator.removeDataAttribute(step, 'is-rendering');
					}
				}, 500);
			},
			error : (data, status, xhr) => {
				setTimeout(function() {
					if(typeof steps[`${steps.current}`].actions.error === 'function') {
						steps[`${steps.current}`].actions.error(data, status, xhr);
					}
				}, 500);
			}
		};

		const Request = new AjaxRequest(o);

		Request.init();
	});

	const renderTpl = () => {
		const currStepTpl = SelectorEngine.findOne('.step', form);
		const stepTpl     = Manipulator.createElementFrom(`<div class="step -${steps.current}"/>`);

		for (const [key, value] of Object.entries(steps[steps.current]['tpl'])) {
			const fieldTpl = Manipulator.createElementFrom(value);

			if(Manipulator.getDataAttribute(fieldTpl, 'step')) {
				EventHandler.on(fieldTpl, 'click', (event) => {
					const step = Manipulator.getDataAttribute(fieldTpl, 'step');

					steps.current = step;

					renderTpl();
				});
			}

			const pwElement = SelectorEngine.findOne('[data-reveal-password]', fieldTpl);

			if(pwElement) {
				RevealPassword.revealPassword(pwElement, {
					triggerText : '<i aria-hidden="true" class="far fa-eye"></i>'
				});
			}

			Manipulator.elementAppend(fieldTpl, stepTpl);
		}

		if(currStepTpl) {
			currStepTpl.replaceWith(stepTpl);
		} else {
			Manipulator.elementPrepend(stepTpl, form);
		}


		// autofocus first input
		const input = SelectorEngine.findOne('input:not([type="hidden"]', form);

		if(input) {
			input.focus();
		}
	};

	renderTpl();
};

const login = () => {
	$(document).on('submit', '#form-login', function(event) {
		event.preventDefault();

		const element = $(this)[0];

		if(element && element.hasAttribute('action')) {
			const o = {
				async : true,
				success   : function(data, status, xhr) {
					const parser       = new DOMParser();
					const responseHtml = parser.parseFromString(xhr.responseText, "text/html");
					const html         = responseHtml.querySelector(Manipulator.getDataAttribute(element, 'ajax-selector'));

					if (html) {
						setTimeout(function() {
							const tpl = html;

							Manipulator.elementAfter(tpl, element);
							Manipulator.setDataAttribute(tpl, 'is-parsed');
							element.remove();
						}, 1000);
					} else {
						console.log('CUSTOM: No Response was found!');

						const drawer  = SelectorEngine.parents(element, '.drawer')[0];
						const api = drawer ? Drawer(drawer) : null;

						writeLoginStatus(function() {
							renderElementsToLoginStatus();
							setTimeout(function() {
								Ajax.init();
							}, 500);
						});

						if(api) {
							api.hide();
						}

						location.reload();
					}
				}
			};

			Ajax.renderSpecificAjaxFormContent(element, o);
		}
	});
};

const logout = () => {
    const collection = SelectorEngine.find('[data-logout]');

    for(const element of collection) {
        if(element.hasAttribute('href')) {
            EventHandler.on(element, 'click', (event) => {
                event.preventDefault();

				const drawer  = SelectorEngine.parents(element, '.drawer')[0];
				const api = drawer ? Drawer(drawer) : null;
				const lPath = window.location.pathname;
				const Request = new AjaxRequest({
					url     : DEFAULTS.url + DEFAULTS.urlParameter.action.logout,
					dataType: 'html',
					success: (data, status, xhr) => {
						writeLoginStatus(function() {

							if(lPath === '/fbm-grundsystem/' || lPath === '/fbm-grundsystem/vasuche.html' || lPath === '/' || lPath === '/vasuche.html') {
								renderElementsToLoginStatus();
								setTimeout(function() {
									Ajax.init();
								}, 500);
							} else {
								renderElementsToLoginStatus();
								location.href = '#';
							}
						});

						if(api) {
							api.hide();
						}

						Toast.render({
							cssClass: 'success',
							content : {
								heading: 'Abmeldung erfolgreich',
								icon   : '<i class="far fa-info-check"></i>',
								msg    : '<span>Sie haben sich erfolgreich abgemeldet!</span>'
							}
						});
					}
				});

                Request.init();
            });
        }
    }
};

// -------
// Public
// -------

const init = () => {
    window.addEventListener("DOMContentLoaded", () => {
        writeLoginStatus();

        const container        = SelectorEngine.findOne(`.drawer#page-login`);
        const toggleCollection = SelectorEngine.find(`[data-drawer-login]`);

        // render Login Drawer
        if(container && toggleCollection) {
            renderDrawer(container, toggleCollection);
        }

        // render all elements equal login status
        setTimeout(function() {
			renderElementsToLoginStatus();
			login();
			logout();
        }, 500);

		newLogin();
    });
};

// -------
// Export
// -------

export default {
	init                      : init,
	writeLoginStatus          : writeLoginStatus,
	renderElementToLoginStatus: renderElementToLoginStatus,
	logout                    : logout
};