/**
 * Komponente ´Toast´
 */

import {
	execute,
	extend,
	getTransitionDuration,
	noop
} from '../../utils/index';
import {
	isElement,
	isString
} from '../../utils/is';

import Data           from '../../dom/data';
import EventHandler   from '../../dom/event-handler';
import SelectorEngine from '../../dom/selector-engine';
import Manipulator    from '../../dom/manipulator';

// -------
// Private
// -------

const NAME       = 'toast';
const DATA_KEY   = `ifab.${NAME}`;
const EVENT_KEY  = `.${DATA_KEY}`;

const DEFAULTS = {
	animation  : true,
	autohide   : true,
	delay      : 5000,
	dismissable: true,
	content    : {
		heading: null,
		icon   : null,
		msg    : null
	},
	layout: {
		header : null,
		body   : null,
		footer : null,
		buttons: null
	}
};

let o = {};

const _renderHeader = () => {
	let tplHeader = Manipulator.createElementFrom('<div class="toast-header"/>');
	
	if(o.content.icon) {
		let tplIcon = Manipulator.createElementFrom(o.content.icon);
		Manipulator.elementAppend(tplIcon, tplHeader);
	}
	
	if(o.content.heading) {
		let tplHeading = Manipulator.createElementFrom('<strong>' + o.content.heading + '</strong>');
		Manipulator.elementAppend(tplHeading, tplHeader);
	}
	
	if(o.dismissable) {
		let tplClose = Manipulator.createElementFrom(' <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>');
		Manipulator.elementAppend(tplClose, tplHeader);
	}
	
	return tplHeader;
};

const _renderBody = () => {
	let tplBody = Manipulator.createElementFrom('<div class="toast-body"/>');
	
	let tplContent = Manipulator.createElementFrom(o.content.msg);
	Manipulator.elementAppend(tplContent, tplBody);
	
	return tplBody;
};

const _renderFooter = () => {
	let tplFooter = Manipulator.createElementFrom('<div class="toast-footer"/>');
	let tplBtn;
	
	for (const [key, value] of Object.entries(o.content.buttons)) {
		tplBtn = Manipulator.createElementFrom(value.tpl);
		
		if(value.func && typeof value.func === 'function') {
			EventHandler.on(tplBtn, 'click', (event) => {
				event.preventDefault();
				
				value.func();
			});
		}
		
		Manipulator.elementAppend(tplBtn, tplFooter);
	}
	
	return tplFooter;
};

// -------
// Public
// -------

const render = (_o = {}) => {
	o = extend({}, DEFAULTS, _o);
	
	const tpl    = {};
	let toastList;
	let cssClass = o.cssClass ? o.cssClass : 'default';
	
	tpl.container = Manipulator.createElementFrom(`<div data-bs-animation="true" role="alert" aria-live="assertive" aria-atomic="true" class="toast -${cssClass}" data-bs-autohide="${o.autohide}">`);
	
	if(o.content.heading || o.content.icon || o.dismissable) {
		tpl._header = _renderHeader();
		Manipulator.elementAppend(tpl._header, tpl.container);
	}
	
	if(o.content.msg) {
		tpl._body = _renderBody();
		Manipulator.elementAppend(tpl._body, tpl.container);
	}
	
	if(o.content.buttons) {
		tpl._footer = _renderFooter();
		Manipulator.elementAppend(tpl._footer, tpl.container);
	}
	
	if(o.content.buttons) {
		toastList = SelectorEngine.findOne('#page-interactive-alerts');
		
		tpl.container.addEventListener('show.bs.toast', () => {
			document.getElementById('page-main').classList.add('-overlay-shown');
		});
		
		tpl.container.addEventListener('hide.bs.toast', () => {
			document.getElementById('page-main').classList.remove('-overlay-shown');
		});
	} else {
		toastList = SelectorEngine.findOne('#page-system-alerts');
	}
	
	tpl.container.addEventListener('hidden.bs.toast', () => {
		tpl.container.remove();
	});
	
	Manipulator.elementAppend(tpl.container, toastList);
	
	const Toast = new bootstrap.Toast(tpl.container, o);
	Toast.show();
};

const init = () => {

};

// -------
// Export
// -------

export default {
	init  : init,
	render: render
};
